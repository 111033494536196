import React, { useEffect, useState } from 'react';

import { downloadRelease, listReleases, listProducts } from '../../services/firmwareHub.service';
import { canAccess } from '../../services/helpers';
import Loading from '../../components/Loading.component';
import Separator from '../../components/Separator.component';
import {
  truncateString
} from '@moreirapontocom/npmhelpers';

import './FirmwareHub.scss';

function FirmwareHub(props: any) {
  // Do not add any other code here
  useEffect(() => {
    if (!canAccess(props.user, 'firmwareHub')) {
        window.location.href = '/home';
        return;
    }
  }, [props.user, props.user.role]);

  useEffect(() => {
    getProducts();
  }, []);

  const [product, setProduct] = useState(null as any);

  /**
   * This converts the JSON object (from firmwarehub_configuration_v2.json) into an array of objects
   * @param payload 
   * @returns 
   */
  const _parseProductsJson = (payload: any) => {
    const final: any[] = [];
    const generations = Object.keys(payload);

    generations.forEach((generation) => {
      Object.entries(payload[generation]).forEach(([key, value]) => {
        final.push({
          product: key,
          repositories: [],
        });
      });
    });

    final.map((product) => {
      Object.entries(payload).forEach(([generation, value]) => {
        Object.entries(payload[generation]).forEach(([key, value]) => {
          if (key === product.product) {
            Object.entries(payload[generation][key]).forEach(([key2, value2]) => {
              product.repositories.push({
                firmwareType: key2.replace("aptitude-reader-systems/", ""),
                branch: payload[generation][key][key2].branch,
                version: payload[generation][key][key2].version,
                args: payload[generation][key][key2].args || [],
              });
            });
          }
        });
      });
    });

    return final;
  };

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);
  const getProducts = async () => {
    setLoadingProducts(true);
    const res: any = await listProducts().then((response: any) => response).catch((err: any) => err);
    if (res.message === "OK") {
      const productsParsed: any = _parseProductsJson(res.products);
      setProducts(productsParsed);
      setLoadingProducts(false);
      return;
    }

    console.error('Error fetching releases', res);
    setLoadingProducts(false);
  };

  const [loadingReleases, setloadingReleases] = useState(false);
  const [releases, setReleases] = useState([]);
  const getReleases = async () => {
    setloadingReleases(true);
    const res: any = await listReleases({product}).then((response: any) => response).catch((err: any) => err);
    if (res.message === "OK") {
      setReleases(res.repositories);
      setloadingReleases(false);
      return;
    }

    console.error('Error fetching releases', res);
    setloadingReleases(false);
  };

  const [loadAllVersions, setLoadAllVersions] = useState(false);

  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDownloadId, setLoadingDownloadId] = useState(null as any);
  const downloadItem = async (releaseId: string, firmwareType: string) => {
    setLoadingDownload(true);
    setLoadingDownloadId(releaseId);
    const res = await downloadRelease(releaseId, firmwareType).then((response: any) => response).catch((err: any) => err);
    if (res.message === "OK") {
      window.open(res.downloadUrl, '_blank');
      setLoadingDownloadId(null);
      setLoadingDownload(false);
      return;
    }

    console.error('Error fetching releases', res);
    setLoadingDownloadId(null);
    setLoadingDownload(false);
  };

  const isCurrentRelease = (item: any, release: any): boolean => {
    // {version#}-{product name}-{branch}
    const name: string = `${item.version}-${product.product}-${item.branch}`;
    return release.name === name;
  };

  return (
    <div className='container'>

      <h2>
        Firmware Hub <i className="fas fa-code-branch ms-2"></i>
      </h2>
      <p className="text-muted">
        Download the latest firmware versions for your devices.<br />
        Current version is based on <strong>"[version]-[product name]-[branch]"</strong>
      </p>


      <Separator size={30} />

      <div className="card bg-light border-0">
        <div className="card-body">

          <div className="row align-items-center">
            <div className="col-2">
              <label>Choose the product</label>
            </div>
            <div className="col">
              <div className="form-group">
                
                <select
                  disabled={loadingProducts || loadingReleases || products.length === 0}
                  onChange={(e) => {
                    setProduct(products.find((repository: any) => repository.product === e.target.value));
                    setReleases([]);
                  }}
                  className="form-select">
                    <option value="">--</option>

                    {products.map((item: any) => {
                      return <option key={`item-product-list-${item.product}`} value={item.product}>{item.product}</option>;
                    })}
                </select>
              </div>
            </div>
            <div className="col-2 text-end">
              <div className="d-grid">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => getReleases()}
                  disabled={loadingProducts || loadingReleases || !product}>
                    <Loading loading={loadingReleases} parent="inline" color="text-white" />
                    {!loadingReleases && <i className="fas fa-list me-2"></i>} Get Releases
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Separator size={10} />

      <Loading loading={loadingReleases} />

      {(product && !loadingProducts && !loadingReleases && product && releases.length > 0) && <>

        <div className="row align-items-center">
          <div className="col">

            <small className="text-muted">
              {releases.length} firmwares found for this product
            </small>

          </div>
          <div className="col text-end">

            <button className="btn btn-outline-warning btn-sm" onClick={() => setLoadAllVersions(!loadAllVersions)}>
              <i className="fas fa-exclamation-triangle me-2"></i> {!loadAllVersions ? "Show" : "Hide"} All Versions
            </button>

          </div>
        </div>

        <Separator size={30} />

        <Loading loading={loadingReleases && releases.length === 0} />

        {releases.length > 0 && releases.map((item: any, indexFirmwareType: number) => {
          return <React.Fragment key={`firmware-type-item-${item.firmwareType}-${indexFirmwareType}`}>
            <div className="row">
              <div className="col-5">
                <h3>
                  <strong>&#8227; {item.firmwareType}</strong>
                </h3>
                <strong>Branch: </strong> {item.branch}<br />
                <strong>Version: </strong> {item.version}<br />

                <Separator size={10} />

                <small className="text-muted">
                  {item.releases.length || 0} releases found
                </small>
              </div>
              <div className="col">

                <div className="accordion" id="accordionExample">
                  {item.releases && item.releases.length > 0 && item.releases.map((release: any, indexRelease: number) => {

                    return (loadAllVersions || (!loadAllVersions && isCurrentRelease(item, release))) && <React.Fragment key={`accordion-${release.id}-${indexRelease}`}>

                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${release.id}`} aria-expanded="true" aria-controls={`collapseOne${release.id}`}>
                            {release.name} {isCurrentRelease(item, release) && <div className="badge bg-primary ms-3">current version</div>}
                          </button>
                        </h2>
                        <div id={`collapseOne${release.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                          <div className="accordion-body">

                            <small className='text-muted'>{release.assets.length} assets</small>

                            <Separator size={10} />

                            {release.assets.map((version: any, indexAsset: number) => (
                              <div key={`asset-${version.id}-${indexAsset}`}>
                                <table border={0} width="100%" cellPadding={10}>
                                  <tbody>
                                    <tr style={{ backgroundColor: indexAsset % 2 === 0 ? "#efefef" : "white" }}>
                                      <td style={{ width: "70%" }}>
                                        <button
                                          className={`btn btn-link p-0 ${loadingDownload && loadingDownloadId === version.id && "opacity-50"}`}
                                          onClick={() => downloadItem(version.id, item.firmwareType)}>
                                            <Loading loading={loadingDownload && loadingDownloadId === version.id} parent="inline" />
                                            {truncateString(version.name, 150, true)}
                                        </button>
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {version.size} bytes
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {version.download_count}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ))}

                          </div>
                        </div>
                      </div>

                    </React.Fragment>
                  })}
                </div>

              </div>
            </div>

            <hr />

          </React.Fragment>
        })}

      </>}

    </div>
  );
}

export default FirmwareHub;
