import React, { useState } from "react";
import Plot from "react-plotly.js";
import Separator from "../Separator.component";

interface DataPoint {
  linearized_temp: {
    left_temperature: number[];
    right_temperature: number[];
    top_temperature: number[];
    time: number[];
  };
  linearized_traces: {
    [key: string]: {
      current: number[];
      time: number[];
    };
  };
  stage_end_time: {
    "end time": number[];
    stage: number[];
  };
  docid: string;
}

interface StageEnd {
  stage: string;
  end_time: number;
}

interface ChartProps {
  dataSets: DataPoint[]; // Array of datasets
}

const LinearizedCharts: React.FC<ChartProps> = ({ dataSets }) => {
  const allTraces: any[] = [];
  const allTemps: any[] = [];
  const shapes: any[] = [];

  const [xRange, setXRange] = useState<any>([0, 30]);

  if (!dataSets) {
    return <div>No data</div>;
  }

  dataSets.forEach((dataset, datasetIndex) => {
    const tempData = dataset.linearized_temp;
    const traceData = dataset.linearized_traces;

    // Add stage end lines for this dataset
    dataset.stage_end_time["end time"].forEach((endTime, stageIndex) => {
      shapes.push({
        type: "line",
        xref: "x",
        yref: "paper",
        x0: endTime,
        x1: endTime,
        y0: 0,
        y1: 1,
        line: {
          color: "red",
          dash: "dash",
          width: 2,
        },
        name: `Stage ${dataset.stage_end_time.stage[stageIndex]} - Dataset ${datasetIndex + 1}`,
      });
    });

    // Add temperature traces
    allTemps.push(
      {
        x: tempData.time,
        y: tempData.left_temperature,
        type: "scatter",
        mode: "lines",
        name: `Left Temp (Dataset ${datasetIndex + 1})`,
        line: { color: "red" },
      },
      {
        x: tempData.time,
        y: tempData.right_temperature,
        type: "scatter",
        mode: "lines",
        name: `Right Temp (Dataset ${datasetIndex + 1})`,
        line: { color: "blue" },
      },
      {
        x: tempData.time,
        y: tempData.top_temperature,
        type: "scatter",
        mode: "lines",
        name: `Top Temp (Dataset ${datasetIndex + 1})`,
        line: { color: "green" },
      }
    );

    // Add current traces
    Object.keys(traceData).forEach((channel) => {
      const channelData = traceData[channel];
      allTraces.push({
        x: channelData.time,
        y: channelData.current,
        type: "scatter",
        mode: "lines",
        name: `${channel} (Dataset ${datasetIndex + 1})`,
      });
    });
  });

  // Calculate max values for scaling
  const maxCurrent = Math.max(
    ...allTraces.map((trace) => Math.max(...(trace.y as number[])))
  );
  const maxTemperature = Math.max(
    ...allTemps.map((temp) => Math.max(...(temp.y as number[])))
  );




  // Bidirectional sync between the two charts X axis
  // State to track the shared x-axis range
  // Handler for the relayout event on the first chart
  const handleRelayoutChart1 = (event: any) => {
    const x0: any = event["xaxis.range[0]"] || xRange[0];
    const x1: any = event["xaxis.range[1]"] || xRange[1];
    setXRange([x0, x1]);
  };

  // Handler for the relayout event on the second chart (optional, for bidirectional sync)
  const handleRelayoutChart2 = (event: any) => {
    const x0: any = event["xaxis.range[0]"] || xRange[0];
    const x1: any = event["xaxis.range[1]"] || xRange[1];
    setXRange([x0, x1]);
  };

  return (
    <div className="row justify-content-center">
      <div className="col-6">

        {/* Current Chart */}
        <Plot
          data={allTraces}
          layout={{
            title: "Linearized Trace Data",
            xaxis: {
              title: "Time (min)",
              // autorange: true,
              // range: [0, 30],
              autorange: false,
              range: xRange,
            },
            yaxis: {
              title: "Current (uA)",
              range: [0, maxCurrent < 80 ? 80 : maxCurrent],
              autorange: true,
            },
            // legend: { orientation: "h" },
            legend: {
              x: 1,
              xanchor: "right",
              y: 1,
              yanchor: "top",
            },
            shapes: shapes, // Stage end lines
          }}
          onRelayout={handleRelayoutChart1}
          style={{ width: "100%", height: "600px" }}
        />

        <Separator size={20} />

        {/* Temperature Chart */}
        <Plot
          data={allTemps}
          layout={{
            title: "Linearized Temperature Data",
            xaxis: {
              title: "Time (min)",
              // autorange: true,
              // range: xRange[0] !== null && xRange[1] !== null ? xRange : undefined,
              autorange: false,
              range: xRange,
            },
            yaxis: {
              title: "Temperature (°C)",
              range: [20, maxTemperature < 100 ? 100 : maxTemperature],
              autorange: true,
            },
            // legend: { orientation: "h" },
            legend: {
              x: 1,
              xanchor: "right",
              y: 1,
              yanchor: "top",
            },
            shapes: shapes, // Stage end lines
          }}
          onRelayout={handleRelayoutChart2}
          style={{ width: "100%", height: "600px" }}
        />

      </div>
    </div>
  );
};

export default LinearizedCharts;