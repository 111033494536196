import axios from "axios";

export const getPlotData = async (payload: any) => {
  return await axios.post(`/getPlotData`, payload).then((res) => res.data).catch((err) => err);
};

export const getAmountDataByCollection = async (payload: any) => {
  return await axios.get(`/charts/getAmountDataByCollection/${payload.dataOrigin}`).then((res) => res.data).catch((err) => err);
};
export const getDynamicColumns = async () => {
  return await axios.get(`/charts/getDynamicsColumns`).then((res) => res.data).catch((err) => err);
};

export const importData = async (payload: any) => {
  return await axios.post(`/importData`, payload).then((res) => res.data).catch((err) => err);
};

export const updateColumnValue = async (payload: any) => {
  return await axios.put(`/updateColumnValue`, payload).then((res) => res.data).catch((err) => err);
};

export const downloadPickleFile = async (payload: any): Promise<any> => {
  return await axios.post(`/charts/downloadPickleFile`, payload).then((res) => res.data).catch((err) => err);
};

export const getLogsOfRowId = async (payload: {rowId: string, dataOrigin: string}) => {
  return await axios.post("/charts/getLogsOfRowId", payload).then((response: any) => response.data).catch((err: any) => err);
}

export const getLinearizedDataOfRowId = async (payload: {rowsIds: string[], dataOrigin: string}) => {
  return await axios.post("/charts/getLinearizedDataOfRowId", payload).then((response: any) => response.data).catch((err: any) => err);
}